import { Document } from "../Document/Document";
import { BasicLegalPerson } from "../LegalPerson/LegalPerson";
import { BasicNaturalPerson } from "../NaturalPerson/NaturalPerson";
import { BasicCompetition } from "../Competition/Competition";
import { ApplicationValue } from "./ApplicationValue";
import { BaseEntity } from "src/store/helpers/entityReducer";


export enum ProjectPhase {
    FINANCING_AND_PRE_PRODUCTION = "FINANCING_AND_PRE_PRODUCTION",
    RECORDING_AND_POST_PRODUCTION = "RECORDING_AND_POST_PRODUCTION",
}

export interface FundingSource {
    id: string,
    costsDescription: string | null,
    financingSource: string | null,
    amount: string | null,
}

export type AllSourcesOfFunding = Array<FundingSource>;

export interface Application {
    id: string,
    createdAt: string,
    isSubmitted: boolean,
    competition: BasicCompetition,
    value: ApplicationValue,
    legalApplicant: BasicLegalPerson | null,
    naturalApplicant: BasicNaturalPerson | null,
    script: Document | null,
    synopsis: Document | null,
    statement: Document | null,
    detailedProjectDescription: Document | null,
    signedConfirmationFromDirector: Document | null,
    signedContractsWithProjectAuthors: Document[],
    detailedBudget: Document | null,
    financialPlan: Document[],
    allSourcesOfFunding: AllSourcesOfFunding,
    letterOfIntent: Document | null,
    confirmationFromKinoteka: Document | null
    recordingPlanWithLocations: Document | null,
    listOfMovieAuthorsAssociatesAndMainRoles: Document | null,
    descriptionOfProductionPlanByStages: Document | null,
    descriptionOfRealizationPlanByStages: Document | null,
    listOfMoviesProduced: Document | null,
    dataOnPreviousCofinancing: Document | null,
    directorPreviousWorks: Document | null,
    promoMaterials: Document[]
    biographies: Document[],
    certificatesOfSoldTerritories: Document[],
    evidenceOfParticipationOfOthers: Document[],
    name: string | null,
    description: string | null,
    location: ProjectLocation[] | null,
    realizationTimeFrom: string | null,
    realizationTimeTo: string | null,
    fundsGranted: boolean,
    fullyCompleted: boolean,
    projectPhase: ProjectPhase | null,
    otherDocumentation: Document[],
    previousYearBalanceSheetAndIncomeStatement: Document | null,

    financialConstructionOrProjectFundingPlan: Document[],
    studentBiographies: Document[],
    complementaryRecommendations: Document[],
    complementaryStatements: Document[],

    projectCostsTable: ProjectCostItem[] | null,
    listOfMovieAuthorsAssociatesAndMainRolesJson: Record<string, CitizenshipAndName> | null,
    shortBiographiesAndFilmographiesOfCoauthours: NameBiographyAndFilmography[] | null,
    coproductionPartners: CoproductionPartner[] | null,
    mainRolesFilmography: NameBiographyAndFilmography[] | null,
    otherProjectsApplied: OtherAppliedProject[] | null,
    projectLanguages: string[] | null,
    financialPlanWithFundingSourcesAndFundsStatus: FinancialPlanWithFundingSourcesAndFundsStatus[] | null,
    overviewOfBudgetByBasicItems: Record<string, string> | null,
    dataOnPreviouslySubfinancedProjectsByCinemaFoundation: PreviouslySubfinancedProject[] | null,
    financingAndPreproduction: string | null,
    recordingAndPostproduction: string | null,
    placement: string | null,
    mainProducerName: string | null,
    mainProducerShortBiographyAndFilmography: string | null,
    productionHouseName: string | null,
    productionHouseProfileAndFilmography: string | null,
    longSynopsis: string | null,
    detailedDirectorExplication: string | null,
    projectRepresentedByAgentForSaleOfInternationalRights: boolean | null,
    areThereConfirmedDistributorsInBihAndAbroad: boolean | null,
    productionCategory: ProductionCategory | null,
    scriptType: ScriptType | null,
    adaptationScriptOriginalName: string | null,
    adaptationScriptOriginalAuthor: string | null,
    competitionCategoryToApplyTo: string | null,

    userDocumentsValid: boolean,

    noDetailedBudget: boolean | null,
    noRecordingPlanWithLocations: boolean | null,
    noListOfMovieAuthorsAssociatesAndMainRoles: boolean | null,
    noDescriptionOfProductionPlanByStages: boolean | null,
    noListOfMoviesProduced: boolean | null,
    noDataOnPreviousCofinancing: boolean | null,
    noDirectorPreviousWorks: boolean | null,
    noCertificatesOfSoldTerritories: boolean | null,
    noLetterOfIntent: boolean | null,
    noFinancialPlan: boolean | null,
    noShortBiographiesAndFilmographiesOfCoauthours: boolean | null,

    noOtherDocumentation: boolean | null,
    noPromoMaterials: boolean | null,

    production1SubcategoryCategory: string | null;
}

export enum CompetitionCategoryToApplyTo {
    FeatureFilmsDebutLowBudget = "FeatureFilmsDebutLowBudget",
    FeatureFilmsFeatureFilmAffirmed = "FeatureFilmsFeatureFilmAffirmed",
    FeatureFilmsMinorityBiHCoproductions = "FeatureFilmsMinorityBiHCoproductions",
    FeatureFilmsAlreadySupportedThatChangedForm = "FeatureFilmsAlreadySupportedThatChangedForm",
    ShortFilmsFictionDocumentaryAnimatedExperimental = "ShortFilmsFictionDocumentaryAnimatedExperimental",
    DevelopmentOfScenarioMaterial = "DevelopmentOfScenarioMaterial",
    DevelopmentOfFilmProject = "DevelopmentOfFilmProject",
}

export enum ProductionCategory {
    FEATURE_FILM = "FEATURE_FILM",
    DOCUMENTARY_FILM = "DOCUMENTARY_FILM",
    ANIMATED_FILM = "ANIMATED_FILM",
}

export enum ScriptType {
    ORIGINAL = "ORIGINAL",
    ADAPTATION = "ADAPTATION",
}

export const scriptTypeOptions = [{
    value: ScriptType.ORIGINAL,
    label: "Originalni Scenarij",
}, {
    value: ScriptType.ADAPTATION,
    label: "Adaptacija",
}];

export interface ProjectLocation extends BaseEntity {
    location: string,
    country: string,
    days: number,
}

export interface NameBiographyAndFilmography extends BaseEntity {
    name: string,
    biographyAndFilmography: string,
}

export interface CitizenshipAndName {
    name: string,
    isCitizenOfBiH: boolean,
}

export interface CoproductionPartner extends BaseEntity {
    name: string,
    address: string,
    contactPerson: string,
    companyProfile: string,
}

export interface OtherAppliedProject extends BaseEntity {
    name: string,
    category: string,
    expectedFundsFromFoundation: string,
}

export interface FinancialPlanWithFundingSourcesAndFundsStatus extends BaseEntity {
    fundingSource: string,
    amount: string,
    status: FundingSourcesFundsStatus,
}

export interface ProjectCostItem extends BaseEntity {
    costDescription: string,
    fundingSource: string,
    amount: string,
}

export enum FundingSourcesFundsStatus {
    Applied = "Applied",
    Confirmed = "Confirmed",
    Planned = "Planned",
}

export const fundingSourcesFundsStatusOptions = [
    { value: FundingSourcesFundsStatus.Applied, label: "Aplicirano" },
    { value: FundingSourcesFundsStatus.Confirmed, label: "Potvrđeno" },
    { value: FundingSourcesFundsStatus.Planned, label: "U planu" },
];

export interface PreviouslySubfinancedProject extends BaseEntity {
    year: number,
    name: string,
    category: string,
    status: string,
    fundsReceivedFromFoundation: string,
}

export class ApplicationUtils {
}
