import { cloneDeep, merge } from "lodash";
import { StorageKeys } from "../utils/storage_keys";
import { SwalUtils } from "../utils/swal_utils";
import { Errors } from "src/utils/consts";
import * as Sentry from "@sentry/react";
import i18n from "i18next";

export enum APIEntity {
    Applications = "applications",
    Competitions = "competitions",
    CompetitionGroups = "competition_groups",
}

export enum ApiMethod {
    Get = "GET",
    Post = "POST",
    Put = "PUT",
    Delete = "DELETE",
}

export enum ApiSortOrder {
    ASC = "ASC",
    DESC = "DESC"
}

export interface APIError {
    detail?: Errors,
    title: string,
    type: string,
}

function isApiError(error: object): error is APIError {
    return error.hasOwnProperty("detail") && error.hasOwnProperty("title");
}

export interface APIPaginatedEndpointParams {
    perPage: number,
    page: number,
    sortBy?: string | null,
    sortOrder?: ApiSortOrder
}

export interface ApiCallParams {
    url: string,
    signal?: AbortSignal,
    noAuth?: boolean,
    headers?: Record<string, string>,
    body?: ReadableStream<Uint8Array> | string | null | FormData,
    jsonData?: Record<string, unknown>,
    method?: ApiMethod,
    manualErrorHandling?: boolean,
    manualUnknownErrorHandling?: boolean
    onApiError?: (error: APIError) => void,
}

export interface GenericErrorResponse {
    detail: string,
    violations: Array<AuthErrorResponse>
}

export interface AuthErrorResponse {
    message: string,
    propertyPath: string,
}

export interface DefaultApiCallParams {
    headers: Record<string, string>,
    method: ApiMethod,
    body?: ReadableStream<Uint8Array> | string | null,
}

export class API {
    public static readonly endpoint: string = import.meta.env.VITE_ENDPOINT || "http://localhost:8000";
    public static readonly apiEndpoint: string = API.endpoint + "/api";
    public static readonly storageEndpoint: string = import.meta.env.VITE_ENDPOINT ? (import.meta.env.VITE_ENDPOINT + "/storage") : "http://localhost:8001";
    public static readonly contentUrl: string = API.storageEndpoint + "/content";
    public static readonly publicEndpoint: string = API.endpoint + "/api/public";
    public static readonly refreshToken: string = `${API.apiEndpoint}/token/refresh`;
    public static readonly applications: string = `${API.apiEndpoint}/applications`;
    public static readonly users: string = `${API.apiEndpoint}/users`;
    public static readonly login: string = `${API.apiEndpoint}/login_check`;
    public static readonly me: string = `${API.apiEndpoint}/me`;
    public static readonly documents: string = `${API.apiEndpoint}/documents`;

    public static readonly legalPeople: string = `${API.apiEndpoint}/legal_people`;

    public static readonly naturalPeople: string = `${API.apiEndpoint}/natural_people`;
    public static readonly competitions: string = `${API.apiEndpoint}/competitions`;
    public static readonly documentsPublic: string = `${API.publicEndpoint}/documents`;
    public static readonly applicationSettings: string = `${API.publicEndpoint}/application_settings`;

    static getApiSingleEntityUrl(entity: APIEntity): string {
        // if (entity === APIEntity.PatientsActiveConversations) {
        //     return `${API.getApiEntityUrl(APIEntity.Patients)}/conversations`;
        // }

        return API.getApiEntityUrl(entity);
    }

    static getApiEntityUrl(entity: APIEntity): string {
        return `${API.apiEndpoint}/${entity}`;
    }

    static getPublicApiEntityUrl(entity: APIEntity): string {
        return `${API.publicEndpoint}/${entity}`;
    }

    static getApiEntityPaginatedUrl(entity: APIEntity, {
        perPage = 30,
        page = 1,
        sortBy,
        sortOrder,
    }: APIPaginatedEndpointParams): string {
        const url = new URL(API.getApiEntityUrl(entity));

        url.searchParams.append("per_page", perPage.toString());
        url.searchParams.append("page", page.toString());

        if (sortBy) {
            url.searchParams.append("sort_by", sortBy);
            url.searchParams.append("sort_order", sortOrder ?? ApiSortOrder.DESC);
        }

        return url.toString();
    }

    static handleErrors(response: Response): Response | undefined {
        if (response.ok || response.status === 204) {
            return response;
        } else {
            throw response;
        }
    }

    public static async jsonApiCall<T>(params: ApiCallParams): Promise<T | null> {
        const r = await API.apiCall(params);

        if (r && r.status !== 204) {
            return await r.json();
        } else {
            return null;
        }
    }

    public static apiCall(params: ApiCallParams = {
        url: "",
        body: undefined,
        jsonData: undefined,
        method: ApiMethod.Get,
        noAuth: false,
        manualErrorHandling: false,
        manualUnknownErrorHandling: false,
    }): Promise<Response | undefined> {
        const originalParams = cloneDeep(params);

        const token = localStorage.getItem(StorageKeys.Token);

        const defaultParams: DefaultApiCallParams = {
            headers: {
                "content-type": "application/json",
                "accept": "application/json, application/ld+json",
            },
            method: ApiMethod.Get,
            body: null,
        };

        if (params.body) {
            delete defaultParams.headers["content-type"];
        }

        if (params.jsonData) {
            params.body = JSON.stringify(params.jsonData);
            delete params.jsonData;
        }

        if (token && !params.noAuth) {
            defaultParams.headers["Authorization"] = `Bearer ${token}`;
        }

        const actualParams = merge(defaultParams, params);

        const p = new Promise<Response | undefined>((resolve, reject) => {
            return fetch(params.url, actualParams)
                .then(this.handleErrors)
                .then(r => resolve(r))
                .catch(async ex => {
                    if (ex.status === 401) {
                        if (ex.json) {
                            const res = await ex.json();

                            if (isApiError(res)) {
                                if (params.onApiError) {
                                    params.onApiError(res);
                                }
                            }

                            const message = res.message;

                            if (message === "Invalid credentials.") {
                                // this is login, propagate this error up
                                // showSwalToast(message);
                                reject({ status: ex.status, message });
                            } else if (message === "Expired JWT Token") {
                                // we should probs refresh token here
                                const refreshToken = localStorage.getItem(StorageKeys.RefreshToken);

                                if (refreshToken) {
                                    // proceed
                                    const data = new FormData();
                                    data.append("refresh_token", refreshToken);
                                    return fetch(API.refreshToken, {
                                        method: "post",
                                        body: data,
                                    })
                                        .then(this.handleErrors)
                                        .then(r => r?.json())
                                        .then(r => {
                                            localStorage.setItem(StorageKeys.Token, r.token);
                                            localStorage.setItem(StorageKeys.RefreshToken, r.refresh_token);
                                            //redo api call
                                            API.apiCall(originalParams)
                                                .then(resolve);
                                        })
                                        .catch(() => {
                                            API.logOut();
                                        });
                                } else {
                                    API.logOut();
                                }
                            } else if (message === "An authentication exception occurred.") {
                                // USER PROBABLY GOT DISABLED
                                SwalUtils.showErrorSwalToast(message);
                                API.logOut();
                            } else if (message === "JWT Token not found") {
                                SwalUtils.showErrorSwalToast(message);
                                API.logOut();
                            } else if (message === "Invalid JWT Token") {
                                API.logOut();
                            }
                        }
                    } else if (ex.status === 404) {
                        // showSwalToast(ex.url + '\n' + ex.statusText);
                    } else if (ex.message === "Failed to fetch") {
                        const isFound = window.location.href.indexOf("reload-page") !== -1;

                        if (!isFound) {
                            window.location.href = `${window.location.origin}/reload-page?to=${window.location.href}`;
                        }
                    } else {
                        if (params.manualErrorHandling) {
                            reject(ex);
                            return;
                        }

                        if (ex.json) {
                            try {
                                const res = await ex.json();

                                if (isApiError(res)) {
                                    if (params.onApiError) {
                                        params.onApiError(res);
                                    }
                                }

                                let errorMessage = "";

                                if (!res.error) {
                                    errorMessage = `${i18n.t<string>("internal_server_error")}.`;
                                }


                                if (res.violations?.length) {
                                    errorMessage = this.resolveApiError(res.violations[0].message);
                                } else if (res.detail) {
                                    errorMessage = this.resolveApiError(res.detail);
                                } else if (res.error) {
                                    errorMessage = this.resolveApiError(res.error);
                                }

                                if (errorMessage) {
                                    SwalUtils.showErrorSwalToast(errorMessage);
                                }
                            } catch (parseJsonEx) {
                                if (params.manualUnknownErrorHandling) {
                                    reject(ex);
                                } else {
                                    SwalUtils.showErrorSwalToast("Desila se nepoznata greška");
                                    reject("Desila se nepoznata greška");
                                }
                            }
                        }
                    }

                    reject(ex);
                });
        });

        return p;
    }

    public static logOut(): void {
        localStorage.removeItem(StorageKeys.Token);
        localStorage.removeItem(StorageKeys.RefreshToken);
        window.location.href = "/";
    }

    static resolveApiError(error: string) {
        switch (error) {
            case Errors.ERR_MISSING_DATA:
                return i18n.t("err_missing_data");
            case Errors.ERR_FORBIDDEN_MEDIA_OBJECT_EXTENSION:
                return i18n.t("err_forbidden_media_object_extension");
            case Errors.ERR_IMAGE_RESOLUTION_TOO_BIG:
                return i18n.t("err_image_resolution_too_big");
            case Errors.ERR_ENTITY_DOES_NOT_EXIST:
                return i18n.t("err_entity_does_not_exist");
            case Errors.ENTITY_OBJECT_NOT_FOUND:
                return i18n.t("entity_object_not_found");
            case Errors.ERR_BAD_PAYLOAD:
                return i18n.t("err_bad_payload");
            case Errors.ERR_NATURAL_PERSON_CANT_APPLY:
                return i18n.t("err_natural_person_cant_apply");
            case Errors.ERR_LEGAL_PERSON_CANT_APPLY:
                return i18n.t("err_legal_person_cant_apply");
            case Errors.ERR_NOT_ALLOWED_TO_SET_STATUS:
                return i18n.t("err_not_allowed_to_set_status");
            case Errors.PASSWORD_IS_MISSING:
                return i18n.t("password_is_missing");
            case Errors.ERR_MISSING_REQUIRED_DOCUMENTS:
                return i18n.t("err_missing_required_documents");
            case Errors.ERR_ENTITY_UPDATES_FORBIDDEN:
                return "Ažuriranja nisu dozvoljena";
            case Errors.ERR_COMPETITION_EXPIRED:
                return "Konkurs je istekao.";
            default:
                Sentry.captureMessage(`Missing error key - ${error}`);
                return error;
        }
    }
}

export function progressFetch<T>(params: ApiCallParams & {
    uploadProgress: (progress: number) => void
}): Promise<T | null> {
    const originalParams = cloneDeep(params);

    if (params.signal) {
        if (params.signal.aborted) {
            return Promise.reject(new DOMException("Aborted", "AbortError"));
        }
    }

    const token = localStorage.getItem(StorageKeys.Token);

    const defaultParams: {
        headers: Record<string, string>,
        method: string,
        body: any,
    } = {
        headers: {
            "content-type": "application/json",
        },
        method: "GET",
        body: null,
    };

    if (params.body) {
        delete defaultParams.headers["content-type"];
    }

    if (params.jsonData) {
        params.body = JSON.stringify(params.jsonData);
        delete params.jsonData;
    }

    if (token) {
        defaultParams.headers["Authorization"] = `Bearer ${token}`;
    }

    const actualParams = merge(defaultParams, params);

    return new Promise((resolve, reject) => {
        async function reqListener(this: XMLHttpRequest, ev: ProgressEvent) {
            if (this.status >= 400) {
                if (this.status === 401) {
                    if (this.response.json) {
                        const message = await this.response.json()
                            .then((data: AuthErrorResponse) => {
                                return data.message;
                            });
                        if (message === "Invalid credentials.") {
                            // this is login, propagate this error up
                            // showSwalToast(message);
                            reject({ status: this.status, message });
                        } else if (message === "Expired JWT Token") {
                            // we should probs refresh token here
                            const refreshToken = localStorage.getItem(StorageKeys.RefreshToken);

                            if (refreshToken) {
                                // proceed
                                const data = new FormData();
                                data.append("refresh_token", refreshToken);
                                return fetch(API.refreshToken, {
                                    method: "post",
                                    body: data,
                                })
                                    .then(API.handleErrors)
                                    .then(r => r?.json())
                                    .then(r => {
                                        localStorage.setItem(StorageKeys.Token, r.token);
                                        localStorage.setItem(StorageKeys.RefreshToken, r.refresh_token);
                                        //redo api call
                                        progressFetch<T>(originalParams)
                                            .then(resolve);
                                    })
                                    .catch(() => {
                                        API.logOut();
                                    });
                            } else {
                                API.logOut();
                            }
                        } else if (message === "An authentication exception occurred.") {
                            // USER PROBABLY GOT DISABLED
                            SwalUtils.showErrorSwalToast(message);
                            API.logOut();
                        } else if (message === "JWT Token not found") {
                            SwalUtils.showErrorSwalToast(message);
                            API.logOut();
                        } else if (message === "Invalid JWT Token") {
                            API.logOut();
                        }
                    }
                }

                try {
                    let json = JSON.parse(this.response);
                    reject(json);
                } catch (ex) {
                    reject({});
                }
            } else {
                let json = JSON.parse(this.responseText);
                actualParams.uploadProgress(100);
                resolve(json);
            }
        }

        function fail() {
            reject("Error");
        }

        function aborted() {
            reject(new DOMException("Aborted", "AbortError"));
        }

        function progress(ev: ProgressEvent) {
            if (ev.lengthComputable) {
                let percentComplete = ev.loaded / ev.total * 100;
                actualParams.uploadProgress(Math.round(percentComplete));
            } else {
                actualParams.uploadProgress(50);
            }
        }

        let oReq = new XMLHttpRequest();

        if (typeof actualParams.uploadProgress === "function") {
            oReq.upload.addEventListener("progress", progress, false);
        }

        oReq.addEventListener("load", reqListener);
        oReq.addEventListener("error", fail);
        oReq.addEventListener("abort", aborted);

        oReq.open(actualParams.method, actualParams.url);

        if (actualParams.signal) {
            actualParams.signal.addEventListener("abort", () => {
                oReq.abort();
                aborted();
            });
        }

        for (let [name, value] of Object.entries(actualParams.headers)) {
            oReq.setRequestHeader(name, value);
        }

        oReq.send(actualParams.body || null);
    });
}
