import { Leaves } from "src/utils/type_utils";
import {
    AllSourcesOfFunding,
    Application,
    CitizenshipAndName,
    CoproductionPartner,
    FinancialPlanWithFundingSourcesAndFundsStatus, FundingSourcesFundsStatus,
    NameBiographyAndFilmography,
    OtherAppliedProject,
    PreviouslySubfinancedProject,
    ProductionCategory,
    ProjectCostItem,
    ProjectLocation,
    ProjectPhase,
    ScriptType,
} from "src/entities/Application/Application";
import { cloneDeep } from "lodash";
import { v4 } from "uuid";
import { getYear } from "date-fns";

export interface IApplicationValueForm {
    totalValue?: string,
    requestedValue?: string,
    givenValue?: string,
}


export interface IApplicationValueSubmitForm {
    totalValue?: string,
    requestedValue?: string,
    givenValue?: string,
}

export interface IApplicationForm {
    isSubmitted?: boolean,
    competition?: string,
    value?: IApplicationValueForm,
    script?: string | null,
    synopsis?: string | null,
    statement?: string | null,
    signedConfirmationFromDirector?: string | null,
    signedDirectorContract?: string | null,
    signedScreenwriterContract?: string | null,
    detailedBudget?: string | null,
    confirmationFromKinoteka?: string | null
    financialPlan?: string[],
    allSourcesOfFunding?: AllSourcesOfFunding
    letterOfIntent?: string | null,
    recordingPlanWithLocations?: string | null,
    listOfMovieAuthorsAssociatesAndMainRoles?: string | null,
    descriptionOfProductionPlanByStages?: string | null,
    legalApplicant?: string | null,
    naturalApplicant?: string | null,
    biographies?: string | null[],
    listOfMoviesProduced?: string | null,
    dataOnPreviousCofinancing?: string | null,
    directorPreviousWorks?: string | null,
    promoMaterials?: Array<string | null>,
    certificatesOfSoldTerritories?: Array<string | null>,
    evidenceOfParticipationOfOthers?: Array<string | null>,
    name?: string | null,
    description?: string | null,
    location?: ProjectLocation[] | null,
    realizationTimeFrom?: string | null,
    realizationTimeTo?: string | null,
    projectPhase?: ProjectPhase | null,
    production1SubcategoryCategory?: string | null;

    projectCostsTable?: ProjectCostItem[] | null,
    listOfMovieAuthorsAssociatesAndMainRolesJson?: Record<string, CitizenshipAndName> | null,
    shortBiographiesAndFilmographiesOfCoauthours?: NameBiographyAndFilmography[] | null,
    coproductionPartners?: CoproductionPartner[] | null,
    mainRolesFilmography?: NameBiographyAndFilmography[] | null,
    otherProjectsApplied?: OtherAppliedProject[] | null,
    projectLanguages?: string[] | null,
    financialPlanWithFundingSourcesAndFundsStatus?: FinancialPlanWithFundingSourcesAndFundsStatus[] | null,
    overviewOfBudgetByBasicItems?: Record<string, string> | null,
    dataOnPreviouslySubfinancedProjectsByCinemaFoundation?: PreviouslySubfinancedProject[] | null,
    financingAndPreproduction?: string | null,
    recordingAndPostproduction?: string | null,
    placement?: string | null,
    mainProducerName?: string | null,
    mainProducerShortBiographyAndFilmography?: string | null,
    productionHouseName?: string | null,
    productionHouseProfileAndFilmography?: string | null,
    longSynopsis?: string | null,
    detailedDirectorExplication?: string | null,
    projectRepresentedByAgentForSaleOfInternationalRights?: boolean | null,
    areThereConfirmedDistributorsInBihAndAbroad?: boolean | null,
    productionCategory?: ProductionCategory | null,
    scriptType?: ScriptType | null,
    adaptationScriptOriginalName?: string | null,
    adaptationScriptOriginalAuthor?: string | null,
    competitionCategoryToApplyTo?: string | null,

    noDetailedBudget?: boolean | null,
    noRecordingPlanWithLocations?: boolean | null,
    noListOfMovieAuthorsAssociatesAndMainRoles?: boolean | null,
    noDescriptionOfProductionPlanByStages?: boolean | null,
    noListOfMoviesProduced?: boolean | null,
    noDataOnPreviousCofinancing?: boolean | null,
    noDirectorPreviousWorks?: boolean | null,
    noCertificatesOfSoldTerritories?: boolean | null,
    noLetterOfIntent?: boolean | null,
    noFinancialPlan?: boolean | null,
    noShortBiographiesAndFilmographiesOfCoauthours?: boolean | null,

    noOtherDocumentation?: boolean | null,
    noPromoMaterials?: boolean | null,
    otherDocumentation?: string[],
    financialConstructionOrProjectFundingPlan?: string[],
    studentBiographies?: string[],
    complementaryRecommendations?: string[],
    complementaryStatements?: string[],
}

export type IApplicationFormError = Partial<Record<Leaves<IApplicationForm>, string>>;

export interface IApplicationSubmitForm {
    isSubmitted?: boolean,
    competition?: string,
    value?: IApplicationValueSubmitForm,
    script?: string | null,
    synopsis?: string | null,
    signedConfirmationFromDirector?: string | null,
    signedDirectorContract?: string | null,
    signedScreenwriterContract?: string | null,
    detailedBudget?: string | null,
    confirmationFromKinoteka?: string | null
    financialPlan?: string[],
    letterOfIntent?: string | null,
    recordingPlanWithLocations?: string | null,
    listOfMovieAuthorsAssociatesAndMainRoles?: string | null,
    descriptionOfProductionPlanByStages?: string | null,
    legalApplicant?: string | null,
    naturalApplicant?: string | null,
    biographies?: string | null[],
    listOfMoviesProduced?: string | null,
    dataOnPreviousCofinancing?: string | null,
    directorPreviousWorks?: string | null,
    promoMaterials?: Array<string | null>
    certificatesOfSoldTerritories?: Array<string | null>,
    evidenceOfParticipationOfOthers?: Array<string | null>,
    name?: string | null,
    realizationTimeFrom?: string | null,
    realizationTimeTo?: string | null,
    projectPhase?: ProjectPhase | null,


    projectCostsTable?: ProjectCostItem[] | null,
    listOfMovieAuthorsAssociatesAndMainRolesJson?: Record<string, CitizenshipAndName> | null,
    shortBiographiesAndFilmographiesOfCoauthours?: NameBiographyAndFilmography[] | null,
    coproductionPartners?: CoproductionPartner[] | null,
    mainRolesFilmography?: NameBiographyAndFilmography[] | null,
    otherProjectsApplied?: OtherAppliedProject[] | null,
    projectLanguages?: string[] | null,
    financialPlanWithFundingSourcesAndFundsStatus?: FinancialPlanWithFundingSourcesAndFundsStatus[] | null,
    overviewOfBudgetByBasicItems?: Record<string, string> | null,
    dataOnPreviouslySubfinancedProjectsByTheCinemaFoundation?: PreviouslySubfinancedProject[] | null,
    financingAndPreproduction?: string | null,
    recordingAndPostproduction?: string | null,
    placement?: string | null,
    shortBiographyAndFilmographyOfTheMainProducer?: string | null,
    productionHouseName?: string | null,
    productionHouseProfileAndFilmography?: string | null,
    longSynopsis?: string | null,
    detailedDirectorExplication?: string | null,
    projectRepresentedByAgentForSaleOfInternationalRights?: boolean | null,
    areThereConfirmedDistributorsInBihAndAbroad?: boolean | null,
    productionCategory?: ProductionCategory | null,
    scriptType?: ScriptType | null,
    adaptationScriptOriginalName?: string | null,
    adaptationScriptOriginalAuthor?: string | null,
    competitionCategoryToApplyTo?: string | null,


    noDetailedBudget?: boolean | null,
    noRecordingPlanWithLocations?: boolean | null,
    noListOfMovieAuthorsAssociatesAndMainRoles?: boolean | null,
    noDescriptionOfProductionPlanByStages?: boolean | null,
    noListOfMoviesProduced?: boolean | null,
    noDataOnPreviousCofinancing?: boolean | null,
    noDirectorPreviousWorks?: boolean | null,
    noCertificatesOfSoldTerritories?: boolean | null,
    noLetterOfIntent?: boolean | null,
    noFinancialPlan?: boolean | null,
    noShortBiographiesAndFilmographiesOfCoauthours?: boolean | null,

    noOtherDocumentation?: boolean | null,
    noPromoMaterials?: boolean | null,
    otherDocumentation?: string[],

    financialConstructionOrProjectFundingPlan?: string[],
    studentBiographies?: string[],
    complementaryRecommendations?: string[],
    complementaryStatements?: string[],
}

export const defaultApplicationFormLocation: ProjectLocation[] = [{
    id: v4(),
    location: "",
    country: "",
    days: 1,
}];

export const defaultApplicationProjectCostsTable: ProjectCostItem[] | null = [{
    id: v4(),
    amount: "0",
    costDescription: "",
    fundingSource: "",
}];

export const defaultApplicationShortBiographiesAndFilmographiesOfCoauthours: NameBiographyAndFilmography[] | null = [{
    id: v4(),
    biographyAndFilmography: "",
    name: "",
}];

export const defaultApplicationCoproductionPartners: CoproductionPartner[] | null = [{
    id: v4(),
    name: "",
    contactPerson: "",
    address: "",
    companyProfile: "",
}];

export const defaultApplicationMainRolesFilmography: NameBiographyAndFilmography[] | null = [{
    id: v4(),
    name: "",
    biographyAndFilmography: "",
}];

export const defaultApplicationOtherProjectsApplied: OtherAppliedProject[] | null = [{
    id: v4(),
    name: "",
    category: "",
    expectedFundsFromFoundation: "0",
}];

export const defaultApplicationDataOnPreviouslySubfinancedProjectsByCinemaFoundation: PreviouslySubfinancedProject[] | null = [{
    id: v4(),
    name: "",
    year: getYear(new Date()),
    status: "",
    category: "",
    fundsReceivedFromFoundation: "0",
}];

export const defaultApplicationAllSourcesOfFunding: AllSourcesOfFunding = [{
    id: v4(),
    costsDescription: "",
    financingSource: "",
    amount: "0",
}];

export const defaultFinancialPlanWithFundingSourcesAndFundsStatus: FinancialPlanWithFundingSourcesAndFundsStatus[] = [{
    id: v4(),
    fundingSource: "Fondacija za Kinematografiju",
    status: FundingSourcesFundsStatus.Applied,
    amount: "0",
}, {
    id: v4(),
    fundingSource: "Vlastita sredstva",
    status: FundingSourcesFundsStatus.Applied,
    amount: "0",
}];

export const defaultApplicationProjectLanguages: string[] | null = [""];

export type IApplicationFormErrors = Partial<Record<Leaves<IApplicationForm>, string>>

export class ApplicationFormUtils {

    static createForm(application: Application | null): IApplicationForm {
        if (!application) {
            return {};
        }

        return {};
    }

    static validateForm(form: IApplicationForm, isEdit: boolean): IApplicationFormErrors {
        const errors = {};

        return errors;
    }


    static prepareForm(form: IApplicationForm): IApplicationSubmitForm {
        const clonedForm = cloneDeep(form);

        if (JSON.stringify(clonedForm.location) === JSON.stringify(defaultApplicationFormLocation)) {
            clonedForm.location = [];
        }

        if (JSON.stringify(clonedForm.financialPlanWithFundingSourcesAndFundsStatus) === JSON.stringify(defaultFinancialPlanWithFundingSourcesAndFundsStatus)) {
            clonedForm.financialPlanWithFundingSourcesAndFundsStatus = [];
        }

        if (JSON.stringify(clonedForm.projectCostsTable) === JSON.stringify(defaultApplicationProjectCostsTable)) {
            clonedForm.projectCostsTable = [];
        }

        if (JSON.stringify(clonedForm.shortBiographiesAndFilmographiesOfCoauthours) === JSON.stringify(defaultApplicationShortBiographiesAndFilmographiesOfCoauthours)) {
            clonedForm.shortBiographiesAndFilmographiesOfCoauthours = [];
        }

        if (JSON.stringify(clonedForm.coproductionPartners) === JSON.stringify(defaultApplicationCoproductionPartners)) {
            clonedForm.coproductionPartners = [];
        }

        if (JSON.stringify(clonedForm.mainRolesFilmography) === JSON.stringify(defaultApplicationMainRolesFilmography)) {
            clonedForm.mainRolesFilmography = [];
        }

        if (JSON.stringify(clonedForm.otherProjectsApplied) === JSON.stringify(defaultApplicationOtherProjectsApplied)) {
            clonedForm.otherProjectsApplied = [];
        }

        if (JSON.stringify(clonedForm.dataOnPreviouslySubfinancedProjectsByCinemaFoundation) === JSON.stringify(defaultApplicationDataOnPreviouslySubfinancedProjectsByCinemaFoundation)) {
            clonedForm.dataOnPreviouslySubfinancedProjectsByCinemaFoundation = [];
        }

        if (JSON.stringify(clonedForm.allSourcesOfFunding) === JSON.stringify(defaultApplicationAllSourcesOfFunding)) {
            clonedForm.allSourcesOfFunding = [];
        }

        if (JSON.stringify(clonedForm.projectLanguages) === JSON.stringify(defaultApplicationProjectLanguages)) {
            clonedForm.projectLanguages = [];
        }

        return {
            ...clonedForm,
        };
    }
}
