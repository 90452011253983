import React from "react";
import "./support.scss";

function SupportPage() {
    return (
        <div className="page support-page">
            <div className="text-18 text-medium header-text">Podrška</div>

            <div className="support-right">
                <p>
                    Uputstvo - Prijava na korisnički portal
                </p>
                <video controls style={{ width: "100%" }}>
                    <source src="https://admin.fondacijakinematografija.ba/storage/tutorial/Fondacija_za_Kinematografiju_Tutorial_1.mp4"/>
                </video>

                <p>
                    Ukoliko imate poteškoća u korištenju korisničkog portala ili su vam potrebne dodatne informacije, kontaktirajte nas!<br/>
                    <a href="mailto:support@fondacijakinematografija.ba">support@fondacijakinematografija.ba</a>
                </p>
            </div>
        </div>
    );
}

export default SupportPage;
